import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { urls } from './API'
// import { loadClients } from 'src/redux/thunks/clients'

export const historiales = createAsyncThunk(
    'Historial/resume',
    async (values, thunkApi) => {
        try {



             const response = await axios.post(urls.Historial)

           // console.log(response.data.messages)


            return response.data.messages
        } catch (e) {
            return thunkApi.rejectWithValue({
                error: e,
                success: false,
                message: 'Credenciales Erroneas',
            })
        }
    }
)
