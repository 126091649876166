import * as React from 'react'

import SEO from '../components/Commons/SEO'
import {userslist} from '../redux/thunks/Users'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { historiales } from '../redux/thunks/Historial'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';




const HomePage = ():JSX.Element => {
    const [pastevents,setevents]=React.useState([])
    const dispatch= useDispatch()


    useEffect(() => {
        async function pacientes(){
            const a = await dispatch(historiales())
            //console.log(a.payload)
            setevents(a.payload)
            console.log(pastevents)
        }

        pacientes()

    },[])

    return (
        <>
            <SEO title="Historial" />
            <h1>Historial</h1>
            <table style={{ borderCollapse: "collapse",fontSize:"20px", fontStyle: 'italic', border: "3px solid black", width:"100%",textAlign:"center"  }}>
                <thead>
                <th style={{ borderCollapse: "collapse",fontSize:"20px", fontStyle: 'italic', border: "3px solid black",textAlign:"center"  }}>ID</th>
                <th style={{ borderCollapse: "collapse",fontSize:"20px", fontStyle: 'italic', border: "3px solid black",textAlign:"center"  }}>Fecha</th>
                <th style={{ borderCollapse: "collapse", fontSize:"20px", fontStyle: 'italic', border: "3px solid black",textAlign:"center"  }} >Persona</th>
                </thead>
                <tbody>
                {
                    pastevents.map(e =>{
                    return (<tr><td style={{ borderCollapse: "collapse",fontSize:"20px", fontStyle: 'italic', border: "3px solid black",textAlign:"center"  }}>{e.id}</td>
                        <td style={{ borderCollapse: "collapse",fontSize:"20px", fontStyle: 'italic', border: "3px solid black",textAlign:"center"  }}>{e.date}</td>
                        <td style={{ borderCollapse: "collapse",fontSize:"20px", fontStyle: 'italic', border: "3px solid black",textAlign:"center"  }}>{e.name}</td></tr>)
                    }
                    )
                }
                </tbody>
            </table>
        </>
    )
}

export default HomePage
